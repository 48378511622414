* {
  box-sizing: border-box;
}

html {
  font-family: Arial, Helvetica, sans-serif;
}

body{
  margin:0;
  background-color: #3f3f3f;
}